


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';

@Component
export default class HomeSuperAdmin extends Vue {
  routes = Routes;
  medinfoQr = '';
  secondPartnerInfo = null;
  coupons: any = [];
}

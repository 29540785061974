








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';
import HomeSecondPartnerAdmin from '@rs-911/components/HomeSecondPartnerAdmin.vue';
import HomeSuperAdmin from '@rs-911/components/HomeSuperAdmin.vue';
import HomeUser from '@rs-911/components/HomeUser.vue';

@Component({
  components: {
    HomeSecondPartnerAdmin,
    HomeSuperAdmin,
    HomeUser
  }
})
export default class PortalHome extends Vue {
  routes = Routes;
  @Prop() private msg!: string;

  created() {
    if (!this.$store.state.auth.loggedIn) {
      console.log('redirect from portal');
      this.$router.push('/');
    }
    // this.$store.dispatch('coupon/getSubscriptions').then(subscriptions => {
    //   console.log(subscriptions);
    // });
  }
}

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';
import AvailableFeatures from '@rs-911/components/AvailableFeatures.vue';

@Component({
  components: {
    AvailableFeatures
  }
})
export default class HomeSecondPartnerAdmin extends Vue {
  routes = Routes;
  coupons: any = [];
  @Prop() private msg!: string;
  created() {
    this.$store.dispatch('coupon/getAllSecondPartner')
      .then((res) =>  {
        this.coupons = res.data;
      },
      (err) => console.log(err));
  }

  get emailVerified() {
    return this.$store.state.auth.emailVerified;
  }

  get getFRCode() {
    return this.coupons.find((x: any) => x.featureName === '911.first_responder')?.code;
  }

  get getPublicCode() {
    return this.coupons.find((x: any) => x.featureName === '911.standard_user')?.code;
  }
  
}

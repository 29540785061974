


















































































/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';
import AvailableFeatures from '@rs-911/components/AvailableFeatures.vue';

@Component({
  components: {
    AvailableFeatures
  }
})
export default class HomeUser extends Vue {
  routes = Routes;
  secondPartnerInfo = null;
  coupons: any = [];
  img = '';
  medinfoQr = '';
  isFirstInstall = null;
  subsFetched = false;
  @Prop() private msg!: string;
  created() {
    // this.$store.dispatch('rs911/isFirstInstall').then(res => this.isFirstInstall = res.data.isfirstinstall);

    this.$store.dispatch('coupon/getSubscriptions').then(x => {
      this.subsFetched = true;
      if(this.$store.state.coupon.firstResponder && !this.$store.state.coupon.standardUser) {
        this.$router.push(this.routes.portal + '/' + this.routes.firstResponder);
      }
      if(this.$store.state.coupon.standardUser) {
        this.$store.dispatch('medInfo/getQrCode', 400).then(res => {
          this.medinfoQr = 'data:image/png;base64,' + res.data.qrCode;
        });
      }
    });

    // this.$store.dispatch('coupon/checkSubscription', '911.first_responder');
  }
}
